<template>
  <div>
    <div class="scratch-card-wrapper">
      <div class="inner-card">
        <scratch-card
          :key="renderCount"
          :cardWidth="cardWidth"
          :cardHeight="cardHeight"
          :finishPercent="finishPercent"
          :imageUrl="qrUrl"
          :brushUrl="brushUrl"
          :forceReveal="forceReveal"
          @getRedeemAward="getRedeemAward"
        >
          <!-- <div class="scratched-card" v-if="onlyShoppingScratch">
            <img class="top-img" src="../assets/img/congrats.png" alt />
            <h2>बधाई हो !</h2>
            <span class="light-text">आपने जीता है</span>
            <div class="scratch-content-product space-top">
              <img class="scrch-product-img" src="../assets/img/congrats.png" alt />
              <div class="product-text-content">
                <p>{{productName}}</p>
                <span v-if="displayTimeText">छूट समाप्त :</span>
                <span v-if="displayTimeText">{{displayTimeText}}</span>
                <div class="scrh-prdc-price-part">
                  <span>Rs. {{finalPaidAmount}}</span>
                  <strike>Rs. {{mrp}}</strike>
                  <span class="btn" @click="shareScratch()">अभी ख़रीदे</span>
                </div>
              </div>
            </div>
            <span
              v-if="displayTimeText"
              class="scratched-card-botm-text"
            >यह ऑफर सिर्फ {{displayTimeText}} मिनट तक के लिए ही वैद्य है</span>
          </div>
          <div class="scratched-card" v-if="shoppingScratchandMpoints">
            <img class="top-img" src="../assets/img/congrats.png" alt />
            <h2>बधाई हो !</h2>
            <span class="light-text">आपने जीता है</span>
            <span class="mpoints-btn">
              <img :src="rewardIcon" alt />
              {{rewardValue}}
            </span>
            <p class="offer_description">{{offerDescription}}</p>
            <h4>
              <span>और साथ में</span>
            </h4>
            <div class="scratch-content-product">
              <img class="scrch-product-img" :src="thumbnail" alt />
              <div class="product-text-content">
                <p>{{productName}}</p>
                <span v-if="displayTimeText">छूट समाप्त :</span>
                <span v-if="displayTimeText">{{displayTimeText}}</span>
                <div class="scrh-prdc-price-part">
                  <strike>Rs. {{mrp}}</strike>
                  <span>Rs. {{finalPaidAmount}}</span>
                  <span class="btn" @click="shareScratch()">अभी ख़रीदे</span>
                </div>
              </div>
            </div>
            <span
              v-if="displayTimeText"
              class="scratched-card-botm-text"
            >यह ऑफर सिर्फ {{displayTimeText}} मिनट तक के लिए ही वैद्य है</span>
          </div>
          <div class="scratched-card" v-if="onlyMpoints">
            <img class="top-img" src="../assets/img/congrats.png" alt />
            <h2>बधाई हो !</h2>
            <span class="light-text">आपने जीता है</span>
            <span class="mpoints-btn">
              <img :src="rewardIcon" alt />
              {{rewardValue}}
            </span>
            <p class="offer_description">{{offerDescription}}</p>
            <div class="scratched-card-footer">
              <p>दुसरो के साथ नीचे दिए हुए कोड को शेयर करे और पैसे जीते</p>
              <div class="footer-btns">
                <p>
                  <span>कोड</span>
                  <span>{{referralCode}}</span>
                </p>
                <span class="footer-share-btn" @click="shareFunc()">शेयर करे</span>
              </div>
            </div>
          </div> -->
          <div class="scratched-card" v-if="onlyShoppingScratch">
            <div class="upper_part">
              <h2>{{displayMessage}}</h2>
              <div class="img_wrapper cashIcon" v-if="cashIcon">
                <img :src="cashIcon" alt />
              </div>
              <div class="img_wrapper">
                <img class="product-img" :src="thumbnail" alt />
              </div>
              <div class="offer_endtime" v-if="displayTimeText != 'Expired'">
                <span class="text">छूट समाप्त :</span>
                <span class="time">{{displayTimeText}}</span>
              </div>
              <p class="product-name">{{productName}}</p>
              <div class="scrh-prdc-price-part">
                <span>&#8377; {{finalPaidAmount}}</span>
                <strike>&#8377; {{mrp}}</strike>
                <button class="btn" v-if="itemId" @click="shareScratch()">अभी ख़रीदे</button>
                <span
                  v-if="cashBackMessage"
                  class="scratched-card-botm-text"
                >{{cashBackMessage}}</span>
                <span
                  v-if="displayTimeText != 'Expired'"
                  class="scratched-card-botm-text"
                >यह ऑफर सिर्फ दिए हुए समय तक के लिए वैध है| जल्दी करे </span>
                  <span class="scratched-card-botm-text" v-else>इस ऑफर पर छूट समाप्त हो चुकी है</span>
              </div>
            </div>
          </div>

          <div class="scratched-card" v-if="shoppingScratchandMpoints">
            <div class="upper_part">
              <h2>{{displayMessage}}</h2>
              <div class="img_wrapper cashIcon" v-if="cashIcon">
                <img :src="cashIcon" alt />
              </div>
              <div class="img_wrapper">
                <img class="product-img" :src="thumbnail" alt />
              </div>
              <div class="offer_endtime" v-if="displayTimeText != 'Expired'">
                <span class="text">छूट समाप्त :</span>
                <span class="time">{{displayTimeText}}</span>
              </div>
              <p class="product-name">{{productName}}</p>
              <div class="scrh-prdc-price-part">
                <span>&#8377; {{finalPaidAmount}}</span>
                <strike>&#8377; {{mrp}}</strike>
                <button class="btn" v-if="itemId" @click="shareScratch()">अभी ख़रीदे</button>
                <div style="display: inline-block; height:44px">
                 <span
                  v-if="cashBackMessage"
                  class="scratched-card-botm-text"
                >{{cashBackMessage}}</span>
                <span
                  v-if="displayTimeText != 'Expired'"
                  class="scratched-card-botm-text"
                >यह ऑफर सिर्फ दिए हुए समय तक के लिए वैध है| जल्दी करे </span>
                 <span class="scratched-card-botm-text" v-else>इस ऑफर पर छूट समाप्त हो चुकी है</span>
                </div>
              </div>
            </div>
            <div class="lower_part">
              <h4>
                <span>और साथ में</span>
              </h4>
              <span class="mpoints-btn">
                <img :src="rewardIcon" alt />
                {{rewardValue}}
              </span>
            </div>
          </div>

          <div class="scratched-card" v-if="onlyMpoints">
            <div class="upper_part mpointsCoin">
              <h2>{{displayMessage}}</h2>
              <div class="img_wrapper">
                <img class="product-img" src="../assets/img/congrats.png" alt />
              </div>
               <span class="mpoints-btn onlympoints">
              <img :src="rewardIcon" alt />
              {{rewardValue}}
            </span>
            </div>

            <div class="scratched-card-footer">
              <p>दुसरो के साथ नीचे दिए हुए कोड को शेयर करे और पैसे जीते</p>
              <div class="footer-btns">
                <p>
                  <span>कोड</span>
                  <span>{{referralCode}}</span>
                </p>
                <span class="footer-share-btn" @click="shareFunc()">शेयर करे</span>
              </div>
            </div>
          </div>
        </scratch-card>
        <span @click="closeScratch()">
          <img src="../assets/img/shape.svg" alt />
        </span>
      </div>
    </div>
    <div class="confirmation_block" v-if="confirmation">
      <span class="helper"></span>
      <div class="modal_popup">
        <div class="modal_content">
          <div class="mall91_logo">
            <img src="../assets/img/mall91_logo.png" alt="mall91_logo" />
          </div>
          <div class="rating_content">
            <h3>{{ t('spreadSomeLove')}}</h3>
            <p>
              <i class="material-icons">star</i>
              <i class="material-icons">star</i>
              <i class="material-icons">star</i>
              <i class="material-icons">star</i>
              <i class="material-icons">star</i>
            </p>
          </div>
        </div>
        <button type="button" class="btn-danger" @click="notNow()">{{t('later')}}</button>
        <button type="button" class="btn-success" @click="rating()">{{t('rateNow')}}</button>
      </div>
    </div>
  </div>
</template>
<script>
import constants from "../constants.js";
import QR from "../assets/img/scratch.png";
import BRUSH from "../assets/img/brush1.png";

export default {
  name: "ShowScratchCard",
  components: {
    ScratchCard: () => import('./ScratchCard')
  },
  props: {
    scratchArrayValue: {
      type: Object
    },
    scratchSaleId: {
      type: String
    },
    redeemId: {
      type: Number
    }
  },
  data() {
    return {
      renderCount: 0,
      cardWidth: 295,
      cardHeight: 379,
      finishPercent: 70,
      brushUrl: BRUSH,
      qrUrl: QR,
      forceReveal: false,
      scratchArray: [],
      confirmation: false,
      displayMessage: "",
      rewardValue: "",
      congrats: "",
      offerDescription: "",
      rewardIcon: "",
      isClickUrl: false,
      itemId: "",
      offerEndTimeMillis: "",
      onlyShoppingScratch: false,
      shoppingScratchandMpoints: false,
      onlyMpoints: false,
      productName: "",
      thumbnail: "",
      finalPaidAmount: "",
      mrp: "",
      displayTimeText: "",
      shareMessage: "",
      referralCode: "",
       cashBackMessage: '',
      cashIcon: ''
    };
  },
  methods: {
    closeScratch() {
      this.$emit("closeScratch");
    },
    shareScratch() {
      if (this.scratchArrayValue.scratchcardDTO) {
        if (this.scratchArrayValue.scratchcardDTO.clickUrl) {
          window.location.href = this.scratchArrayValue.scratchcardDTO.clickUrl;
        }
      }
    },
    rating() {
      localStorage.setItem("isRating", "appRated");
      this.confirmation = false;
      this.$emit("closeScratch");
      try {
        JSBridgePlugin.openInBrowser(
          "https://play.google.com/store/apps/details?id=com.money91&hl=en_IN"
        );
      } catch (err) {
        console.log(err);
      }
    },
    notNow() {
      this.confirmation = false;
      this.$emit("closeScratch");
    },
    setTimeText(liveStartTimeMilis) {
      var text = "";
      var startDate = new Date(Number(liveStartTimeMilis));
      var now = new Date().getTime();
      var distance = startDate - now;
      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      if (hours < 10) {
        hours = "0" + hours;
      }
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      if (days > 0) {
        text = days + " : " + hours + " : " + minutes + " : " + seconds;
      } else if (hours > 0) {
        text = hours + " : " + minutes + " : " + seconds;
      } else if (minutes > 0) {
        text = "00 : " + minutes + " : " + seconds;
      } else if (seconds > 0) {
        text = "00 : " + minutes + " : " + seconds;
      } else {
        text = "Expired";
      }
      return text;
    },
    getProductDetail() {
      const obj = {
        saleReferenceID: this.scratchArrayValue.scratchcardDTO.itemId,
        orderSourceType: "SCRATCH_VIEW"
      };
      this.$http
        .post(constants.Mall_BASE_URL + "sales-details", obj)
        .then(data => {
          this.productName = data.body.productName;
          this.thumbnail = data.body.itemMediaDTOList[0].thumbnail;
          this.finalPaidAmount = data.body.finalPaidAmount;
          this.mrp = data.body.mrp;
        });
    },
    shareFunc() {
      const obj = {
        message: this.shareMessage
      };
      try {
        JSBridgePlugin.share(JSON.stringify(obj));
      } catch (err) {
        console.log(err);
        window.open("whatsapp://send?text=" + this.shareMessage);
      }
    },
    getRedeemAward() {
      if (this.scratchArrayValue.scratchcardDTO) {
        this.displayMessage = this.scratchArrayValue.scratchcardDTO.displayMessage;
        this.rewardValue = this.scratchArrayValue.scratchcardDTO.rewardValue;
        this.offerDescription = this.scratchArrayValue.scratchcardDTO.offerDescriptionString;
        this.reedemValueIcon = this.scratchArrayValue.rewardIcon;
      }
      if (this.scratchArrayValue.scratchcardDTO.clickUrl) {
        this.isClickUrl = true;
      }
      const obj = {
        // identifierId: this.scratchSaleId,
        applicationId: "149",
        id: this.redeemId
      };
      this.$http
        .post(constants.SCRATCHCARDURL + "redeem-reward", obj)
        .then(function(data) {
          this.offerEndTimeMillis = data.body.offerEndTimeMillis;
          // //=========================testing2412========================================
          // this.offerEndTimeMillis = {
          //   requestStatus: "SUCCESS",
          //   successMessage: "Amount redeem suceessfully!",
          //   failMessage: null,
          //   errorCode: null,
          //   offerEndTimeMillis: 1578588311897
          // };
          this.$cookie.set("scratchProvide", this.scratchSaleId);
          if (
            data.body.offerEndTimeMillis != null &&
            data.body.offerEndTimeMillis > 0
          ) {
            const _this = this;
            _this.interval = setInterval(
              function(_this) {
                _this.displayTimeText = _this.setTimeText(
                  _this.offerEndTimeMillis
                );
              },
              1000,
              _this
            );
          }
        });
    }
  },
  mounted() {
    if (this.scratchArrayValue.scratchcardDTO) {
      this.displayMessage = this.scratchArrayValue.scratchcardDTO.displayMessage;
      this.rewardValue = this.scratchArrayValue.scratchcardDTO.rewardValue;
      this.offerDescription = this.scratchArrayValue.scratchcardDTO.offerDescriptionString;
      this.rewardIcon = this.scratchArrayValue.scratchcardDTO.rewardIcon;
      this.clickUrl = this.scratchArrayValue.scratchcardDTO.clickUrl;
      this.itemId = this.scratchArrayValue.scratchcardDTO.itemId;
      this.shareMessage = this.scratchArrayValue.scratchcardDTO.shareMessage;
      this.referralCode = this.scratchArrayValue.referralCode;
      this.cashBackMessage = this.scratchArrayValue.cashBackMessage;
      this.cashIcon = this.scratchArrayValue.cashIcon;
    }
    if (
      this.scratchArrayValue.scratchcardDTO.itemId &&
      this.scratchArrayValue.scratchcardDTO.rewardValue
    ) {
      this.getProductDetail();
      this.shoppingScratchandMpoints = true;
    }
    if (
      this.scratchArrayValue.scratchcardDTO.itemId &&
      this.scratchArrayValue.scratchcardDTO.rewardValue == null
    ) {
      this.getProductDetail();
      this.onlyShoppingScratch = true;
    }
    if (
      this.scratchArrayValue.scratchcardDTO.itemId == null &&
      this.scratchArrayValue.scratchcardDTO.rewardValue
    ) {
      this.onlyMpoints = true;
    }
  }
};
</script>

<style scoped>
.scratch-card-wrapper {
	 width: 100%;
	 position: fixed;
	 top: 0;
	 z-index: 11;
}
 .scratch-card-wrapper .inner-card {
	 width: 100%;
	 height: 100vh;
	 background: #000000b3;
	 display: flex;
	 align-items: center;
	 flex-direction: column;
	 justify-content: space-around;
	 padding: 15px;
}
 .scratch-card-wrapper .inner-card .scratched-card {
	 width: 295px;
	 text-align: center;
	 border-radius: 6px;
}
 .scratch-card-wrapper .inner-card .scratched-card .upper_part {
	 background-color: #f5efef5e;
	 padding-bottom: 14px;
	 border-top-left-radius: 5px;
	 border-top-right-radius: 5px;
}
 .scratch-card-wrapper .inner-card .scratched-card .upper_part.mpointsCoin {
	 height: auto;
}
 .scratch-card-wrapper .inner-card .scratched-card .img_wrapper {
	 height: 97px;
	 width: 97px;
	 margin: 0 auto;
}
 .scratch-card-wrapper .inner-card .scratched-card .img_wrapper img {
	 max-width: 100%;
	 max-height: 100%;
}
 .scratch-card-wrapper .inner-card .scratched-card .img_wrapper.cashIcon {
	 position: absolute;
	 left: 5px;
}
 .scratch-card-wrapper .inner-card .scratched-card .product-img {
	 border-radius: 50%;
}
 .scratch-card-wrapper .inner-card .scratched-card .product-name {
	 color: #505050;
	 font-weight: 600;
	 margin: 7px 0;
	/* width: max-content;
	 */
	 overflow: hidden;
	 text-overflow: ellipsis;
	 padding: 0 10px;
	 white-space: nowrap;
}
 .scratch-card-wrapper .inner-card .scratched-card h2 {
	 font-size: 0.875em;
	 background: #f93;
	 margin-bottom: 6px;
	 font-weight: 600;
	 color: #fff;
	 padding: 11px;
	 text-align: center;
	 border-top-left-radius: 5px;
	 border-top-right-radius: 5px;
}
 .scratch-card-wrapper .inner-card .scratched-card .mpoints-btn {
	 display: flex;
	 justify-content: center;
	 align-items: center;
	 padding: 7px;
	 font-size: 0.9em;
	 font-weight: 500;
	 color: #373737;
}
 .scratch-card-wrapper .inner-card .scratched-card .mpoints-btn img {
	 height: 28px;
	 margin-right: 8px;
}
 .scratch-card-wrapper .inner-card .scratched-card .onlympoints {
	 font-size: 1em;
	 font-weight: 600;
	 color: #373737;
	 width: max-content;
	 margin: 15px auto 25px;
	 border: 1px solid #f93;
	 border-radius: 5px;
	 background: #fff;
}
 .scratch-card-wrapper .inner-card .scratched-card h4 {
	 width: 100%;
	 text-align: center;
	 /* margin: 8px 0 0; */
	 position: relative;
}
 .scratch-card-wrapper .inner-card .scratched-card h4:before {
	 content: " ";
	 height: 1px;
	 background: #ddd;
	 width: 100%;
	 display: inline-block;
	 position: absolute;
	 left: 0;
	 top: 54%;
	 z-index: 1;
}
 .scratch-card-wrapper .inner-card .scratched-card h4 span {
	 color: #727272;
	 font-size: 0.8em;
	 background: #fff;
	 position: relative;
	 z-index: 2;
	 padding: 0 10px;
	 font-weight: normal;
}
 .scratch-card-wrapper .inner-card .scratched-card .scrh-prdc-price-part {
	 width: 100%;
	 margin-top: 5px;
}
 .scratch-card-wrapper .inner-card .scratched-card .scrh-prdc-price-part span {
	 font-size: 1.125em;
	 font-weight: 600;
	 padding-right: 10px;
}
 .scratch-card-wrapper .inner-card .scratched-card .scrh-prdc-price-part span.scratched-card-botm-text {
	 color: #727272;
	 font-size: 0.68em;
	 display: block;
	 margin-top: 6px;
	 text-align: center;
	 overflow: hidden;
	 white-space: nowrap;
	 text-overflow: ellipsis;
	 width: 295px;
   padding: 0 8px;
   line-height: 1.5
}
 .scratch-card-wrapper .inner-card .scratched-card .scrh-prdc-price-part strike {
	 font-size: 1em;
	 font-weight: normal;
	 color: #727272;
}
 .scratch-card-wrapper .inner-card .scratched-card .scrh-prdc-price-part .btn {
	 border-radius: 3px;
	 background-color: #fff;
	 background: #138808;
	 font-size: 1em;
	 padding: 7px 6px;
	 font-weight: 600;
	 display: block;
	 width: 75%;
	 margin: 8px auto;
	 border: 0;
	 color: #fff;
}
 .scratch-card-wrapper .inner-card .scratched-card .offer_endtime {
	 position: absolute;
	 right: 0;
	 top: 50px;
	 padding: 0 8px;
}
 .scratch-card-wrapper .inner-card .scratched-card .offer_endtime span {
	 display: block;
}
 .scratch-card-wrapper .inner-card .scratched-card .offer_endtime span.text {
	 font-size: 0.875em;
	 color: #727272;
}
 .scratch-card-wrapper .inner-card .scratched-card .offer_endtime span.time {
	 padding: 5px 0;
	 font-weight: 600;
}
 .scratch-card-wrapper .inner-card .scratched-card .scratched-card-footer {
	 background-color: #fff;
	 padding: 16px;
}
 .scratch-card-wrapper .inner-card .scratched-card .scratched-card-footer p {
	 color: #505050;
	 font-size: 0.938em;
	 line-height: 1.6;
	 text-align: center;
	 font-weight: 500;
}
 .scratch-card-wrapper .inner-card .scratched-card .scratched-card-footer .footer-btns {
	 display: flex;
	 align-items: center;
	 justify-content: space-between;
	 margin-top: 15px;
}
 .scratch-card-wrapper .inner-card .scratched-card .scratched-card-footer .footer-btns p {
	 border: solid 1px #ddd;
	 background: #fff;
	 line-height: normal;
}
 .scratch-card-wrapper .inner-card .scratched-card .scratched-card-footer .footer-btns p span:nth-child(1) {
	 font-size: 1em;
	 padding: 3px 4px;
}
 .scratch-card-wrapper .inner-card .scratched-card .scratched-card-footer .footer-btns p span:nth-child(2) {
	 color: #000;
	 font-size: 1em;
	 border-left: solid 1px #ddd;
	 padding: 3px 4px;
	 display: inline-block;
}
 .scratch-card-wrapper .inner-card .scratched-card .scratched-card-footer .footer-btns .footer-share-btn {
	 border-radius: 4px;
	 background-color: #138808;
	 padding: 5px 8px;
	 color: #fff;
	 font-size: 0.86em;
}

</style>
